 body {
  /*Greenish version*/
  /*
  background: rgb(34,193,195);
  background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
  */

  /*Light blue*/
  
  /*background: rgb(181,177,250);
  background: linear-gradient(90deg, rgba(181,177,250,1) 0%, rgba(190,232,231,1) 40%, rgba(187,238,233,1) 60%, rgba(134,235,255,1) 100%);
  */
  background: rgb(184,248,248);
background: -moz-linear-gradient(90deg, rgba(184,248,248,1) 0%, rgba(224,255,255,0.65) 50%, rgba(184,248,248,0.65) 100%);
background: -webkit-linear-gradient(90deg, rgba(184,248,248,1) 0%, rgba(224,255,255,0.65) 50%, rgba(184,248,248,0.65) 100%);
background: linear-gradient(90deg, rgba(184,248,248,1) 0%, rgba(224,255,255,0.65) 50%, rgba(184,248,248,0.65) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b8f8f8",endColorstr="#b8f8f8",GradientType=1);
 


  text-align: center;
  color: #333;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.ComingSoonDesign {
    background-image: url("pictures/ocean-2604848.jpg");
    background-color: #251313; /* Used if the image is unavailable */
    height: 500px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    color: rgb(8, 60, 60);
  }

/* Design of the navigation bar */
.navigation_ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}
.navigation_ul_project {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

.navigation_li {
  list-style-type: none;
  float: left;
  background-color: #333;
}

.navigation_li_project {
  display: block;
  list-style-type: none;
  float: left;
  background-color: #333;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background: #3d0863;
} 


/*Design of the homepage*/
.homepageDesign
{
  text-align:center;

}

.homepageDesignLeft
{
  text-align:left;
}


.intpageNavigation {
  list-style-type: none;
  color: #b86fec;
  display: block;
  justify-content: center;
  gap: 1px;
  line-height: 10px;
}

.intpageNavigation li, a {
  color: #b86fec;
}

/* Design of factories */
.factoryTitle {
  list-style-type: none;
  text-align:center;
  margin: 10px;
  padding: 10px;
  overflow: hidden;
  background: #333;
  color: #b86fec;
}

.factoriesDesign p, h2, h3, h4 {
  margin: 10px;
  padding: 10px;
  text-align: left;
}

.projectListDesign {
  margin: auto;
  padding: 15px;
  background-color: #333;
  opacity: 0.95;
  text-align: center;
  width: 70%;
  border-radius: 20px;
  outline: none;
  border: transparent;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.projectListDesign h4{
  color: #b86fec;
}

.ulMidP
{
  font-weight: bold;
  align-items: center;
}

.requestDesign
{
  text-align:left;
  font-weight: bold;
  color: #b86fec;
  margin: 10px;
  padding: 10px;
  list-style-type:symbols();
  list-style-position: inside;
}



.data img
{
  background-color: #282c34;
  border-radius: 50%;
  padding: 10px;
  margin-top: -60px;
  margin-right: 20px;
  float: right
  ;
}

button
{
  border-radius: 20px;
  outline: none;
  border: #b86fec;
  color: white;
  background: #282c34;
  padding: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.goTopHidden {
  display: none;
}
.goTop {
  display: block;
  &__text {
    position: fixed;
    background: rgba(252,248,241,0.8);
    box-shadow: 2px 2px 5px;
    width: 30px;
    height: 30px;
    border-radius: 25px;
    bottom: 5%;
    left: 90%;
    cursor: pointer;
    text-align: center;
    line-height: 30px;

    &:hover {
      transform: scale(1.1);
    }
  }
}

table {
  padding:10px;
  margin: 10px;
}

/*just added*/
.video-frame {
  height: 85%;
  width: 85%; 
  background: rgb(0, 0, 0);
  border: 1.8px solid #b86fec;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
}
/*just added*/
.video-frame iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.legalsToggle {
  list-style: none;
  text-align:left;
  margin: 10px;
  padding: 10px;
  background: #333;
  color: #b86fec;
  flex-direction: column;
}

.legalsText {
  list-style: none;
  text-align:left;
  margin: 10px;
  padding: 10px;
  background: #333;
  color: white;
}

ul.topnav {background-color: #333};
ul.topnav {list-style-type: none;}


@media screen and (min-width:768px)
{
  ul.topnav {background-color: #333};
  ul.topnav {list-style-type: none;}
}
@media screen and (max-width:768px) {
  
    ul.topnav li.right, 
    ul.topnav li {float: none;}
    ul.topnav {background-color: #333};
    ul.topnav {list-style-type: none;}
    
  
  /* The "responsive" class is added to the topnav with JavaScript when the 
  user clicks on the icon. This class makes the topnav look good 
  on small screens (display the links vertically instead of horizontally) */
  .topnav.responsive {position: relative;}
  .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: table-column;
    text-align: left;
  }

  .projectnav {
    position: absolute;
    float: none;
    display: flex;
    flex-direction: column;
  }

  ul.projectnav {background-color: #333};
  ul.projectnav {list-style-type: none;}
  li.projectnav {flex-direction: column;}

  .container {
    flex-direction: column;
  }


  .buttonDownloadPDF {
    font-weight: bold;
    border-style: solid;
    border: #b86fec;
  }

  .a:hover {
    cursor: pointer;
  }

  .button:hover {
    cursor: pointer;
  }


  .video-frame {
    height: 70%;
    width: 70%; 
    background: rgb(0, 0, 0);
    border: 1.8px solid #b86fec;;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    overflow: hidden;
  }
  /*just added*/
  .video-frame iframe {
    
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
